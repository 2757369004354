import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { FormattedMessage } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { cn, useMount } from '../../shared/lib/utils.ts'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../../shared/ui/drawer/drawer.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import { useSnapshot } from 'valtio'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { Filters, useWordsStore } from './wordsStore.ts'
import { BottomButtonGradient } from '../../shared/ui/gradient/bottomButtonGradient.tsx'
import { Vocabulary } from '../../shared/api/chatApi.ts'
import { urls } from '../../shared/urls.ts'
import Checkbox from '../../shared/ui/checkbox/checkbox.tsx'
import { classed } from '@tw-classed/react'
import { ADVANCED_LESSON_TAG } from '../../shared/data/practice.ts'

const TABS: { text: AppMessageKeys; selector: Filters }[] = [
  { text: 'words.all', selector: 'all' },
  { text: 'words.byDate', selector: 'byDate' },
  { text: 'words.added', selector: 'added' },
]

const BUTTON_OFFSET = 80

const ClassedTab = classed.div(
  'whitespace-nowrap rounded-full px-16 py-4 font-nunito-7-semicondensed text-16 font-bold transition-all',
  {
    variants: {
      isActive: {
        true: 'bg-user-message text-black',
        false: 'bg-gray-light text-gray7',
      },
    },
  },
)

const AddedTab = classed.div(
  'absolute right-24 top-1/2 -translate-y-1/2 rounded-8 border-1 border-yellow3 bg-yellow4 px-12 py-2 font-nunito-7-semicondensed text-12 font-bold text-yellow2',
)

const DeleteButton = classed.div(
  'absolute inset-y-0 right-0 flex h-80 w-half items-center justify-end bg-[#FD473D]',
  {
    variants: {
      isFirstElem: {
        true: 'rounded-t-16',
        false: '',
      },
      isLastElem: {
        true: 'rounded-b-16',
        false: '',
      },
    },
  },
)

export function WordsView() {
  const navigate = useNavigate()
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const location = useLocation()
  const isSelectPage = location.pathname.endsWith('select')
  const currentTabSelector = state.filter.selector

  useMount(() => {
    void store.getAllWords()
  })

  return (
    <div className="w-full pb-104 sm:mx-auto sm:mt-40 sm:max-w-1000 sm:rounded-t-24 sm:bg-white">
      <div className="relative mt-16 flex w-full justify-center">
        <button
          onClick={() => {
            navigate(-1)
          }}
          className="absolute left-16"
        >
          <Icon iconName="left" />
        </button>
        <h3 className="text-18 font-bold">
          <FormattedMessage id={isSelectPage ? 'selectWords' : 'words'} />
        </h3>
      </div>
      <div className="no-scrollbar mt-32 flex gap-8 overflow-x-auto px-16">
        {TABS.map((el, i) => (
          <Tab
            key={`tab-key-${i}`}
            text={el.text}
            isActive={el.selector === currentTabSelector}
            handler={() => {
              store.setFilter(el.selector)
            }}
          />
        ))}
      </div>
      <div>
        {state.words.loading ? (
          <div className="mt-100 flex size-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <WordBlock
            // title={
            //   <p className="font-bold text-16 text-gray-dark">New words(8)</p>
            // }
            words={state.words.list}
          />
        )}
      </div>
      <div className="fixed bottom-0 z-30 w-full bg-white px-16 pb-24 pt-12 sm:max-w-1000">
        <BottomButtonGradient />
        <div
          className={cn(
            'flex gap-8',
            isSelectPage ? 'flex-col' : 'flex-col-reverse',
          )}
        >
          <StartButton isSelectPage={isSelectPage} />
          <AddToWordsDrawer isSelectPage={isSelectPage} />
        </div>
      </div>
    </div>
  )
}

export function Tab({
  isActive,
  text,
  handler,
}: {
  isActive: boolean
  text: AppMessageKeys
  handler?: () => void
}) {
  return (
    <ClassedTab isActive={isActive} onClick={handler}>
      <FormattedMessage id={text} />
    </ClassedTab>
  )
}

export function WordBlock({
  //title,
  words,
}: {
  //title: ReactNode
  words: readonly Vocabulary[] | null
}) {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  if (!words) return null

  return (
    <motion.div
      className="mt-16 flex flex-col gap-8 px-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <div className="flex">
        {/*isSelectPage && <Checkbox className="ml-14 mr-12" />}
        { {title} */}
      </div>
      <div className="flex flex-col">
        {words.map((el, i) => (
          <WordBlockItem
            el={el}
            index={i}
            key={`${el.id}-${i}`}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            isLastElement={i === words.length - 1}
          />
        ))}
      </div>
    </motion.div>
  )
}

const WordBlockItem = ({
  el,
  index,
  openIndex,
  setOpenIndex,
  isLastElement,
}: {
  el: Vocabulary
  index: number
  openIndex: number | null
  setOpenIndex: Dispatch<SetStateAction<number | null>>
  isLastElement: boolean
}) => {
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const x = useMotionValue(0)
  const controls = useAnimation()

  const isUserAdded = el.is_user_added
  const isSelected = state.checkedWords.includes(el.word_normal)
  const location = useLocation()
  const isSelectPage = location.pathname.endsWith('select')

  const getIsDrag = (isUserAdded: boolean) => {
    if (isSelectPage) return false
    return isUserAdded ? 'x' : false
  }

  const handleDelete = (id: number) => {
    //setWords((prev) => prev.filter((_, i) => i !== index));
    if (openIndex === id) {
      setOpenIndex(null)
      void store.deleteWord(id)
    }
  }

  const handlePlay = (word: string) => {
    store.playWord(word)
  }

  const handleToggleCheckWord = (word: string) => {
    store.toggleCheckedWords(word)
  }

  useEffect(() => {
    if (openIndex !== el.id) {
      void controls.start({
        x: 0,
        transition: {
          type: 'spring',
          stiffness: 50,
          damping: 10,
        },
      })
    }
  }, [openIndex, el.id, controls])

  return (
    <motion.div
      key={el.id}
      className="relative"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.3 }}
      onClick={(e) => {
        e.stopPropagation()
        setOpenIndex(el.id)
      }}
    >
      <DeleteButton isFirstElem={index === 0} isLastElem={isLastElement}>
        <button
          onClick={() => {
            handleDelete(el.id)
          }}
          className="mr-18 flex flex-col items-center text-14 font-bold text-white"
        >
          <Icon iconName="deleteWithCross" size="small" />
          <FormattedMessage id="delete" />
        </button>
      </DeleteButton>

      <motion.div
        className={cn(
          'relative z-10 flex items-start gap-8 p-16',
          index === 0 ? 'rounded-t-16' : '',
          isLastElement ? 'rounded-b-16' : '',
          isSelected ? 'bg-user-message' : 'bg-gray-light',
        )}
        style={{ x, touchAction: 'pan-y' }}
        onClick={() => {
          isSelectPage ? handleToggleCheckWord(el.word_normal) : {}
        }}
        drag={getIsDrag(isUserAdded)}
        dragConstraints={{ left: -BUTTON_OFFSET, right: 0 }}
        dragElastic={0.2}
        onDragEnd={(_, info) => {
          if (info.offset.x < -BUTTON_OFFSET / 2) {
            void controls.start({
              x: -BUTTON_OFFSET,
              transition: {
                type: 'spring',
                stiffness: 50,
                damping: 10,
              },
            })
            setOpenIndex(el.id)
          } else {
            void controls.start({
              x: 0,
              transition: {
                type: 'spring',
                stiffness: 50,
                damping: 10,
              },
            })
            setOpenIndex(null)
          }
        }}
        animate={controls}
      >
        {isSelectPage ? (
          <Checkbox
            className="my-auto -ml-2 mr-4"
            checked={state.checkedWords.includes(el.word_normal)}
          />
        ) : (
          <button
            className="cursor-pointer text-blue350"
            onClick={() => {
              handlePlay(el.word_normal)
            }}
          >
            <Icon iconName="speaker" />
          </button>
        )}
        <div className="flex-1 font-nunito-7-semicondensed">
          <p className="text-18 font-bold">{el.word_normal}</p>
          <p className="text-14 font-semibold opacity-50">{el.translation}</p>
        </div>
        {el.is_user_added && (
          <AddedTab>
            <FormattedMessage id="added" />
          </AddedTab>
        )}
      </motion.div>
    </motion.div>
  )
}

function StartButton({ isSelectPage }: { isSelectPage: boolean }) {
  const navigate = useNavigate()
  const store = useWordsStore()
  const wordsState = useSnapshot(store.state)
  const checkedWords = wordsState.checkedWords
  const isDisabled = isSelectPage ? checkedWords.length !== 5 : false
  const handleClick = () => {
    isSelectPage
      ? navigate(urls.vocabLesson(ADVANCED_LESSON_TAG), {
          state: { userVocab: [...checkedWords] },
        })
      : navigate(urls.practiceWords('select'))
  }

  return (
    <Button
      size="extralarge"
      rounded="full"
      bg={isSelectPage ? 'blue-gradient-shadow-inset' : 'transparent'}
      disabled={isDisabled}
      onClick={handleClick}
      className="w-full text-18 font-bold"
    >
      <FormattedMessage id={'startTheGame'} />
    </Button>
  )
}

function AddToWordsDrawer({ isSelectPage }: { isSelectPage: boolean }) {
  const store = useWordsStore()
  const state = useSnapshot(store.state)
  const isLoading = state.addWordDrawer.loading
  const word = state.addWordDrawer.word

  const handleClose = () => {
    store.setAddWordDrawerOpen(false)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    store.setWord(e.target.value)
  }

  return (
    <Drawer open={state.addWordDrawer.open} onClose={handleClose}>
      <DrawerTrigger asChild>
        <Button
          size="extralarge"
          rounded="full"
          bg={isSelectPage ? 'transparent' : 'blue-gradient-shadow-inset'}
          onClick={() => {
            store.setAddWordDrawerOpen(true)
          }}
          className="w-full text-18 font-bold"
        >
          <FormattedMessage id={'addNewWord'} />
        </Button>
      </DrawerTrigger>
      <DrawerContent direction="bottom" onOverlayClick={handleClose}>
        <div className="relative mx-auto flex h-[95dvh] max-w-800 flex-col rounded-t bg-white px-28">
          <DrawerBar />
          <div className="mt-14 text-20 font-bold">
            <FormattedMessage id="addNewWord" />
          </div>
          <TextInput
            size="extralarge"
            className="mt-32 border-purple3"
            value={state.addWordDrawer.word}
            onChange={handleInput}
          />
          <Button
            size="extralarge"
            rounded="full"
            bg="blue-gradient-shadow-inset"
            className="mt-24 w-full text-18 font-bold"
            disabled={!word || isLoading}
            onClick={() => void store.addWordToVocabulary()}
          >
            {isLoading ? <Spinner /> : <FormattedMessage id="add" />}
          </Button>
          <Button
            size="extralarge"
            rounded="full"
            bg="transparent"
            className="mt-12 w-full text-18 font-bold"
            onClick={handleClose}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <DrawerClose asChild onClick={handleClose}>
            <div className="absolute right-28 top-28 flex size-28 items-center justify-center rounded-full bg-gray">
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
