import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { cn, useMount } from '../../shared/lib/utils.ts'
import { urls } from '../../shared/urls.ts'
import {
  MOCK_ADVANCED_LESSON,
  MOCK_VOCAB_LESSONS,
} from '../../shared/data/practice.ts'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { usePracticeStore } from './PracticeStore.ts'
import { useSnapshot } from 'valtio'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

export interface VocabProgressElemType {
  textColor: string
  bgColor: string
  statKey: 'new' | 'weak' | 'strong'
}

export interface VocabProgressTagType {
  bgColor: string
  id: AppMessageKeys
}

interface ProgressHintTagType {
  mainId: AppMessageKeys
  word: string
}

export const VOCAB_PROGRESS_BLOCKS: VocabProgressElemType[] = [
  { textColor: 'text-blue2', bgColor: 'bg-blue', statKey: 'new' },
  { textColor: 'text-orange5', bgColor: 'bg-orange4', statKey: 'weak' },
  { textColor: 'text-green2', bgColor: 'bg-green3', statKey: 'strong' },
]

export const VOCAB_PROGRESS_TAGS: VocabProgressTagType[] = [
  { bgColor: 'bg-blue2', id: 'practice.new' },
  { bgColor: 'bg-orange5', id: 'practice.weak' },
  { bgColor: 'bg-green2', id: 'practice.strong' },
]

export const PROGRESS_HINT_TAGS: ProgressHintTagType[] = [
  { mainId: 'ProgressHintTextNew', word: 'New' },
  { mainId: 'ProgressHintTextWeak', word: 'Weak' },
  { mainId: 'ProgressHintTextStrong', word: 'Strong' },
]

const ProgressElemTag = ({ bgColor, id }: VocabProgressTagType) => {
  return (
    <div className="flex items-center gap-4 font-nunito-7-condensed text-14 font-bold text-gray4">
      <div className={cn('size-8 rounded-full', bgColor)} />
      <FormattedMessage id={id} />
    </div>
  )
}

export const VocabLesson = ({
  img,
  lessonText,
  lessonType,
  lessonTag,
  type,
}: {
  img: string
  lessonText: string
  lessonTag: string
  lessonType: string
  type: 'quick' | 'advanced'
}) => {
  const navigate = useNavigate()
  return (
    <div
      className="relative flex h-fit w-full cursor-pointer gap-12 rounded-16 bg-gray-light p-16"
      onClick={() => {
        type === 'quick'
          ? navigate(urls.vocabLesson(lessonTag), {
              state: { lessonType: 'vocabulary' },
            })
          : navigate(urls.practiceWords('select'))
      }}
    >
      <div
        className={cn(
          'relative flex size-80 shrink-0 items-center justify-center rounded-full border-2 p-6',
          'border-lesson-icon-border',
        )}
      >
        <div
          className={cn(
            'flex size-full items-center justify-center overflow-hidden rounded-full',
            'bg-white',
          )}
        >
          <img className="w-full" src={img} alt="" />
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <p className="text-14 font-extrabold text-black opacity-30">
          {lessonType}
        </p>
        <p className="text-18 font-bold">{lessonText}</p>
      </div>
    </div>
  )
}

export const PracticeView = () => {
  const store = usePracticeStore()
  const state = useSnapshot(store.state)
  const wordsCount = state.words.count
  const hints = state.hints
  useMount(() => {
    void store.getPracticeInfo()
  })
  const navigate = useNavigate()
  const intl = useIntl()

  const [progressBlockOffset, setProgressBlockOffset] = useState<number | null>(
    null,
  )

  const progressBlockRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (progressBlockRef.current) {
      const offsetTop =
        progressBlockRef.current.getBoundingClientRect().top + window.scrollY
      setProgressBlockOffset(offsetTop)
    }
  }, [])

  if (state.words.loading)
    return (
      <div className="flex h-dvh w-full items-center justify-center">
        <Spinner />
      </div>
    )

  return (
    <div className="relative w-full px-16 pb-100 pt-16 sm:mx-auto sm:w-800 xl:pb-0  ">
      <h1 className="text-center text-18 font-bold">
        <FormattedMessage id="practice.header" />
      </h1>
      <Button
        size="small"
        rounded="full"
        bg="blue-gradient-shadow-inset"
        onClick={() => {
          navigate(urls.practiceWords())
        }}
        disabled={state.words.loading}
        className="absolute right-16 top-12 font-nunito-7-condensed"
      >
        {wordsCount}{' '}
        <FormattedMessage id="wordsPlural" values={{ count: wordsCount }} />
      </Button>
      <div className="mt-28 flex h-72 items-center justify-center text-center text-48 font-black">
        {wordsCount}
      </div>
      <Button
        size="small"
        rounded="full"
        bg="transparent"
        className="mx-auto flex gap-6 border-2 border-lesson-icon-border px-16 py-2 font-nunito-7-semicondensed text-12 font-bold text-lesson-icon-border"
        onClick={() => {
          navigate(urls.practiceWords())
        }}
      >
        <Icon iconName="plus" size="xs" />
        <FormattedMessage id="addNewWord" />
      </Button>
      {/* <div className="mt-12 h-fit w-full rounded-12 bg-gray-light p-32 text-center text-gray7">
        <p className="text-18 font-bold">
          <FormattedMessage id="practice.wordStatEmpty" />
        </p>
        <p className="mt-12 text-14">
          <FormattedMessage id="practice.takeFirstLesson" />
        </p>
      </div> */}

      <p className="mt-24 text-center text-14 text-gray4">
        <FormattedMessage
          id="metrics.footerText"
          values={{
            boldText: (
              <span className="font-bold">
                {intl.formatMessage({ id: 'metrics.footerBoldSpan' })}
              </span>
            ),
          }}
        />
      </p>

      <ProgressBar
        className="mt-24 flex h-132 w-full overflow-hidden rounded-12"
        ref={progressBlockRef}
      />
      <ProgressTags className="mt-12 flex w-full justify-center gap-16 rounded-b-18" />

      <MockLessons type="quick" />
      <MockLessons type="advanced" />
      <AnimatePresence mode="wait">
        {hints !== null &&
          progressBlockOffset !== null &&
          hints.includes('marketingPracticeHint') && (
            <PracticeViewHint topOffset={progressBlockOffset} />
          )}
      </AnimatePresence>
    </div>
  )
}

const ProgressBar = forwardRef<HTMLDivElement, { className: string }>(
  ({ className }, ref) => {
    const store = usePracticeStore()
    const state = useSnapshot(store.state)
    return (
      <div className={className} ref={ref}>
        {VOCAB_PROGRESS_BLOCKS.map((el, i, arr) => {
          const rounded =
            i === 0
              ? 'rounded-l-12'
              : i === arr.length - 1
              ? 'rounded-r-12'
              : ''
          const value = state.stat ? state.stat[el.statKey] : null
          const width = state.stat
            ? (state.stat[el.statKey] / state.stat.sum) * 100
            : 0

          return (
            <div
              key={`progress-${el.statKey}`}
              className={cn('relative h-full min-w-52', el.bgColor, rounded)}
              style={{ width: `${width}%` }}
            >
              <p
                className={cn(
                  'absolute bottom-4 right-12 text-24 font-extrabold',
                  el.textColor,
                )}
              >
                {value}
              </p>
            </div>
          )
        })}
      </div>
    )
  },
)

ProgressBar.displayName = 'ProgressBar'

const ProgressTags = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      {VOCAB_PROGRESS_TAGS.map((el, i) => (
        <ProgressElemTag
          key={`progress-el-tag-${i}`}
          bgColor={el.bgColor}
          id={el.id}
        />
      ))}
    </div>
  )
}

const MockLessons = ({ type }: { type: 'quick' | 'advanced' }) => {
  const MOCK = type === 'quick' ? MOCK_VOCAB_LESSONS : MOCK_ADVANCED_LESSON
  return (
    <>
      <h2 className="mt-24 text-22 font-bold">
        <FormattedMessage
          id={type === 'quick' ? 'quickStart' : 'advancedMode'}
        />
      </h2>
      <div className="mt-6 flex flex-col gap-16">
        {MOCK.map((el, i) => (
          <VocabLesson
            img={el.img}
            lessonText={el.lessonText}
            lessonType={el.lessonType}
            lessonTag={el.lessonTag}
            key={`vocab-lesson-${i}`}
            type={type}
          />
        ))}
      </div>
    </>
  )
}

type MarketingHintSteps = 'vocabModal' | 'progressModal'

const PracticeViewHint = ({ topOffset }: { topOffset: null | number }) => {
  const [hintStep, setHintStep] = useState<MarketingHintSteps>('vocabModal')
  const store = usePracticeStore()
  if (topOffset === null) return null

  return (
    <motion.div
      className="fixed left-0 top-0 z-50 flex size-full max-h-dvh  bg-black-80"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence mode="wait" initial={false}>
        {hintStep === 'vocabModal' && (
          <VocabModalHint handler={setHintStep} key="vocabModal" />
        )}
        {hintStep === 'progressModal' && (
          <ProgressModalHint
            handler={() => { store.deleteHint('marketingPracticeHint'); }}
            key="progressModal"
            topOffset={topOffset}
          />
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const VocabModalHint = ({
  handler,
}: {
  handler: React.Dispatch<React.SetStateAction<MarketingHintSteps>>
}) => {
  return (
    <motion.div
      className="relative mx-16 my-auto flex h-fit w-full flex-col items-center overflow-hidden rounded-12 bg-white pb-20 text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <img src="/images/practiceHintHeader.png" className="" />
      <h3 className="font-nunito-7-semicondensed text-28 font-black text-black">
        <FormattedMessage id="VocabHintHeader" values={{ br: <br /> }} />
      </h3>
      <p className="mx-28 mt-8 font-nunito-7-semicondensed text-16 font-normal">
        <FormattedMessage id="VocabHintText" />
      </p>
      <Button
        size="extralarge"
        bg="blue-gradient-shadow-inset"
        className="mt-20 w-[calc(100%-32px)]"
        rounded="full"
        onClick={() => { handler('progressModal'); }}
      >
        <FormattedMessage id="Continue" />
      </Button>
    </motion.div>
  )
}

const ProgressModalHint = ({
  handler,
  topOffset,
}: {
  handler: () => void
  topOffset: number
}) => {
  const marginTop = `mt-[${topOffset}px]`
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={() => { handler(); }}
      className="size-full px-10"
    >
      <ProgressBar
        className={cn(
          'flex h-132 w-full overflow-hidden rounded-16 bg-white p-6',
          marginTop,
        )}
      />
      <ProgressTags className="mx-auto flex w-fit justify-center gap-16 rounded-b-18 bg-white px-16 py-6" />
      <p className="mt-28 px-24 text-24 font-extrabold text-white">
        <FormattedMessage id="ProgressHintHeader" />
      </p>
      <div className="mx-auto mt-10 px-36">
        {PROGRESS_HINT_TAGS.map((el) => (
          <HintText id={el.mainId} word={el.word} key={el.mainId} />
        ))}
      </div>
      <p className="mt-16 px-36 text-16 font-semibold text-white">
        <FormattedMessage id="ProgressHintFooter" />
      </p>
    </motion.div>
  )
}

const HintText = ({ id, word }: { id: AppMessageKeys; word: string }) => {
  return (
    <p className="font-nunito-7-semicondensed text-16 font-semibold text-white">
      <FormattedMessage
        id={id}
        values={{
          word: <span className="font-bold">{word}</span>,
        }}
      />
    </p>
  )
}
