import { Outlet } from 'react-router-dom'
import { Toasts } from '../features/toasts/toasts.tsx'
import { Menu } from '../features/rightmenu/menu.tsx'
import { useIsMobile } from '../shared/lib/hooks.ts'
import React from 'react'
import { cn } from '../shared/lib/utils.ts'
import { Navbar } from '../features/navbar/navbar.tsx'
import { MainContainer } from '../shared/ui/card/card.tsx'

export function Layout(props: {
  withMenu: boolean
  withNavbar: boolean
  fullWidth?: boolean
  scroll?: boolean
  white?: boolean
  customContainer?: boolean
}) {
  const isMobile = useIsMobile()
  return (
    <div
      className={cn(
        'w-full [scrollbar-width:thin]',
        props.white ? 'bg-white xl:bg-bglight' : 'bg-light',
      )}
    >
      <div className="relative flex min-h-dvh gap-40">
        {props.withNavbar && (isMobile ? <Navbar /> : <Menu />)}
        {props.withMenu && !isMobile && <Menu />}

        <MainContainer
          enable={!props.customContainer && props.withNavbar && !isMobile}
        >
          <Outlet />
        </MainContainer>
      </div>
      <Toasts />
    </div>
  )
}
