import { useSnapshot } from 'valtio'
import { AnswerStatusDrawer } from '../exercises/answerStatusDrawer.tsx'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { VocabLessonStore } from './vocabLessonStore.ts'
import { classed } from '@tw-classed/react'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { memo } from 'react'
import { cn } from '../../shared/lib/utils.ts'
import {
  MessageContent,
  isArticle,
  isExercise,
  isImage,
} from '../chats/chatStore.ts'
import { match, P } from 'ts-pattern'
import { MatchWords } from '../exercises/matchWords/matchWords.tsx'
import { WriteTheGapss } from '../exercises/writeTheGaps.tsx'
import { FillTheGaps } from '../exercises/fillTheGaps/fillTheGaps.tsx'
import { TrueOrFalseExercise } from '../exercises/trueOrFalse/TrueOrFalseExercise.tsx'
import { ListeningExercise } from '../exercises/listeningExercise/listeningExercise.tsx'
import { ErrorBoundary } from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { Button } from '../../shared/ui/button/button.tsx'
import { LessonIsCompleted } from '../lessonCompleted/lessonCompleted.tsx'
import { LessonStatistics } from '../lessonStatistics/lessonStatistics.tsx'

export const VocabStoreContext = createStoreContext<{
  vocabLessonStore: VocabLessonStore
}>()

export function useVocabStore() {
  return useStoreContext(VocabStoreContext)
}

const VocabViewContainer = classed.div(
  'relative mx-auto flex w-full flex-col items-center sm:max-w-800',
)

const VocabExerciseContainer = classed.div(
  'relative flex w-full flex-col gap-12 ',
  {
    variants: {
      isVocab: {
        true: 'mt-64 sm:mt-0',
        false: 'mt-auto',
      },
    },
  },
)

const MessageContentContainer = classed.div('w-fit rounded-12 bg-white', {
  variants: {
    image: {
      true: 'overflow-hidden',
      false: 'p-16',
    },
  },
})

export function VocabSkeleton() {
  return (
    <div className="mt-104 h-[460px] w-full px-16">
      <div className="relative flex size-full items-center justify-center overflow-hidden rounded-18 bg-gray-light before:absolute before:left-[0%] before:top-0 before:h-[460px] before:w-full before:animate-skeleton  before:bg-[linear-gradient(90deg,rgba(227,230,239,0),rgba(227,230,239,0.5),rgba(227,230,239,0))] before:content-['']" />
    </div>
  )
}

export function VocabView(props: { className?: string }) {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state)

  return (
    <VocabViewContainer className={props.className}>
      {state.isLoading && !state.isLastExercise ? (
        <VocabSkeleton />
      ) : (
        <>
          <VocabList />
          <Footer />
        </>
      )}
      <AnswerStatusDrawer />
    </VocabViewContainer>
  )
}

function VocabList() {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state)
  const exercise = state.exercise
  return (
    <div
      className={cn(
        'flex w-full flex-1 overflow-y-auto bg-white px-16 pt-40 [scrollbar-width:thin]',
      )}
    >
      <VocabExerciseContainer isVocab={state.lessonType === 'vocabulary'}>
        {exercise?.content && (
          <VocabExerciseContentView
            completed={true}
            content={exercise.content}
            key={exercise.index}
          />
        )}
      </VocabExerciseContainer>
      <LessonIsCompletedBlock />
      <LessonStatisticsBlock />
    </div>
  )
}

const LessonStatisticsBlock = memo(() => {
  const store = useVocabStore().vocabLessonStore
  const { statistics } = useSnapshot(store.state)
  if (statistics.isLoading)
    return (
      <div className="fixed left-0 top-0 z-30 flex h-[100dvh] w-full items-center justify-center bg-white">
        <Spinner />
      </div>
    )
  if (statistics.isStatPage) {
    return <LessonStatistics store={store} />
  }
  return null
})

LessonStatisticsBlock.displayName = 'LessonStatisticsBlock'

function VocabExerciseContentView(props: {
  content: MessageContent
  completed: boolean
}) {
  const store = useVocabStore().vocabLessonStore
  //   const state = useSnapshot(store.state)
  const hasImage = isImage(props.content)
  const content = match(props.content)
    .with({ image: P._ }, () => <></>)
    .with({ article: P._ }, () => <></>)
    .with({ text: P._ }, () => {
      return <></>
    })
    .with({ matchWords: P._ }, (x) => (
      <MatchWords
        completed={props.completed}
        words={x.matchWords}
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
      />
    ))
    .with({ writeTheGaps: P._ }, (x) => (
      <WriteTheGapss
        completed={props.completed}
        texts={x.writeTheGaps}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .with({ fillTheGaps: P._ }, (x) => (
      <FillTheGaps
        completed={props.completed}
        texts={x.fillTheGaps}
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
        onTaskComplete={(x) => { store.onTaskComplete(x); }}
      />
    ))
    .with({ trueOrFalse: P._ }, (x) => (
      <TrueOrFalseExercise
        onComplete={(x, y) => {
          store.onExerciseComplete(x, y)
        }}
        questions={x.trueOrFalse}
      />
    ))
    .with({ listening: P._ }, (x) => (
      <ListeningExercise
        onComplete={(x, y) => {
          store.onExerciseComplete(x, y)
        }}
        completed={props.completed}
        questions={x.listening}
      />
    ))
    .exhaustive()
  if (isExercise(props.content) || isArticle(props.content)) {
    return content
  }
  return (
    <ErrorBoundary
      fallback={
        <span className="text-alert">
          <FormattedMessage id="contentError" />
        </span>
      }
    >
      <MessageContentContainer image={hasImage}>
        {content}
      </MessageContentContainer>
    </ErrorBoundary>
  )
}

export const ChatTextInput = () => {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state, { sync: true })

  const completeLessonButton = (
    <Button
      size="extralarge"
      className="w-full"
      rounded="full"
      disabled={!state.lessonCompleted}
      bg="blue-gradient"
      onClick={() => {
        store.goToLessonCompletedPage()
      }}
    >
      <FormattedMessage id="Complete the lesson" />
    </Button>
  )
  if (!state.lessonCompleted) return null
  return (
    <div className="relative flex h-[148px] w-full shrink-0 items-center bg-white px-16 sm:mx-0">
      <div className="absolute -top-18 left-0 h-20 w-full bg-gradient-to-t from-white from-30% to-transparent"></div>
      {completeLessonButton}
    </div>
  )
}

export const Footer = () => {
  return <ChatTextInput />
}

const LessonIsCompletedBlock = memo(() => {
  const store = useVocabStore().vocabLessonStore
  const state = useSnapshot(store.state)

  if (state.statistics.isLessonCompletedPage && !state.isLoading) {
    return <LessonIsCompleted store={store} />
  }
  return null
})

LessonIsCompletedBlock.displayName = 'VocabLessonCompletedBlock'
