export const ADVANCED_LESSON_TAG = 'Vocab practice ML'

export const MOCK_VOCAB_LESSONS = [
  {
    img: '/images/program/lessons/kimono.png',
    lessonText: 'Match Game',
    lessonType: 'Match Pairs',
    lessonTag: 'Vocab practice MW',
  },
  {
    img: '/images/program/lessons/speaker.png',
    lessonType: 'Audio Match',
    lessonText: 'Sound Game',
    lessonTag: 'Vocab practice MA',
  },
  {
    img: '/images/program/lessons/basketball.png',
    lessonType: 'Multiple choice',
    lessonText: 'Choice Game',
    lessonTag: 'Vocab practice MC',
  },
  {
    img: '/images/program/lessons/teacher.png',
    lessonType: 'Complex lesson',
    lessonText: 'Big Word Game',
    lessonTag: 'Vocab practice CL',
  },
]

export const MOCK_ADVANCED_LESSON = [
  {
    img: '/images/program/lessons/tennis.png',
    lessonType: 'Choose words by yourself',
    lessonText: 'Play Your Game',
    lessonTag: ADVANCED_LESSON_TAG,
  },
]
