/* eslint-disable valtio/state-snapshot-rule */
import {
  ProgramContext,
  useProgramStore,
} from '../features/programs/programView.tsx'
import { useLazyRef } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { cn, sortBy, useMount } from '../shared/lib/utils.ts'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../shared/ui/drawer/drawer.tsx'
import { Icon } from '../shared/ui/icon/icon.tsx'
import { ProgramItem } from '../shared/api/chatApi.ts'
import { LinkButton } from '../shared/ui/button/button.tsx'
import { urls } from '../shared/urls.ts'
import { useNavigate } from 'react-router-dom'
import { BottomButtons } from '../shared/ui/bottomButtons.tsx'
import { AppMessageKeys } from 'src/shared/translations/messages.ts'

export const LEVELS_MAP = {
  A1: { title: 'Elementary', subtitle: 'A1-A2' },
  A2: { title: 'Basic', subtitle: 'A2-B1' },
  B1: { title: 'Ambitious', subtitle: 'B1-B2' },
  B2: { title: 'Confident', subtitle: 'B2+' },
  C1: { title: 'Efficient', subtitle: 'B2-C1' },
  C2: { title: 'Fluent', subtitle: 'C1' },
}

export function ProgramPage2(props: { registration: boolean }) {
  const appModule = useAppModule()

  const store = useLazyRef(() =>
    appModule.programStore(props.registration),
  ).current

  useMount(() => {
    void store.loadPrograms()
  })

  return (
    <ProgramContext.Provider value={store}>
      <div className="flex w-full flex-col overflow-hidden bg-white">
        <ProgramPageHeader />

        <div className="bg-white pt-16 msm:pt-100">
          <TopicsList />
        </div>

        <Units />
      </div>
    </ProgramContext.Provider>
  )
}

function ProgramPageHeader() {
  return <ProgramPageHeaderMobile />
}

function ProgramPageHeaderMobile() {
  return (
    <div
      className={cn(
        'flex h-100 w-full items-center',
        'fixed left-0 top-0 z-10 bg-white xl:static',
      )}
    >
      <div className="flex w-full items-center justify-between pr-12">
        <div className="mx-auto">
          <LevelBottomSheet />
        </div>
      </div>
    </div>
  )
}

export function LessonWithDrawer({
  lesson,
  index,
}: {
  lesson: ProgramItem
  index: number
}) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const store = useProgramStore()
  const state = useSnapshot(store.state)
  const navigate = useNavigate()
  const isCompleted = lesson.status === 'completed'

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    if (lesson.status === 'new') {
      navigate(urls.lesson(lesson.tag))
    } else {
      setIsOpen(true)
    }
  }
  return (
    <Drawer open={isOpen} onClose={handleClose} dismissible={false}>
      <DrawerTrigger
        onClick={() => {
          handleOpen()
        }}
        className="w-full sm:max-w-400"
      >
        <Lesson
          id={lesson.id}
          tag={lesson.tag}
          isSelected={lesson.id === state.selectedLessonId}
          isCompleted={isCompleted}
          inProgress={lesson.status === 'active'}
          index={index + 1}
          title={lesson.lesson_subject}
          type_name={lesson.lesson_name}
          type={lesson.lesson_type}
          isFirstLesson={state.isFirstLesson}
          order={lesson.order}
        />
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
        onOverlayClick={handleClose}
      >
        <DrawerBar />
        <div className="mt-24 flex h-auto flex-col">
          <DrawerClose
            asChild
            onClick={handleClose}
            className="absolute right-28"
          >
            <div className="flex size-32 shrink-0 items-center justify-center rounded-full bg-gray">
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
          <div className="flex flex-col px-28 ">
            <div className="text-22 font-bold">
              <FormattedMessage
                id={isCompleted ? 'Repeat the lesson?' : 'Continue the lesson?'}
              />
            </div>
            <div className="mt-14">
              <FormattedMessage
                id={
                  isCompleted ? 'You already complete' : 'You are in progress'
                }
              />
            </div>
            <BottomButtons
              firstButtonProps={{
                handler: () => {
                  navigate(urls.lesson(lesson.tag), {
                    state: { recreateChat: isCompleted },
                  })
                },
                textId: isCompleted
                  ? 'Repeat the lesson'
                  : 'Continue the lesson',
                bg: 'blue-gradient',
                visible: true,
              }}
              secondButtonProps={{
                handler: () => {
                  navigate(urls.lesson(lesson.tag), {
                    state: {
                      recreateChat: !isCompleted,
                      mode: isCompleted ? 'readonly' : 'full',
                    },
                  })
                },
                textId: isCompleted ? 'Lesson review' : 'Restart the lesson',
                bg: 'transparent',
                visible: true,
                withIcon: false,
              }}
              wrapperClassname="flex flex-col gap-8 mt-24 mb-24"
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export function LevelBottomSheet() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const [isOpen, setIsOpen] = React.useState(false)
  const level = state.selectedLevel as keyof typeof LEVELS_MAP
  const isClevel = level === 'C1' || level === 'C2'

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <DrawerTrigger
        asChild
        onClick={() => {
          setIsOpen((prev) => !prev)
        }}
      >
        <button className="flex cursor-pointer items-center gap-8 p-0 text-18 font-semibold text-black outline-0">
          {state.selectedLevel && (
            <FormattedMessage
              id="personalCourse"
              values={{
                level: (
                  <FormattedMessage
                    id={LEVELS_MAP[level].title as AppMessageKeys}
                  />
                ),
              }}
            />
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={5}
            fill="none"
          >
            <path
              fill="#000"
              d="M3.937 4.732.174.969a.61.61 0 0 1-.13-.186.573.573 0 0 1 .115-.61.533.533 0 0 1 .413-.173h7.923c.17 0 .307.058.413.174a.573.573 0 0 1 .159.398c0 .041-.059.174-.175.398L5.13 4.732a.821.821 0 0 1-.283.193.867.867 0 0 1-.314.056.867.867 0 0 1-.314-.056.821.821 0 0 1-.283-.193Z"
            />
          </svg>
        </button>
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
        overlayClassName="bg-black"
      >
        <DrawerBar />
        <div className="mt-24 flex h-[90dvh] flex-col">
          <div className="flex justify-between gap-28 px-28 ">
            <div className="ml-12 text-22 font-bold">
              <FormattedMessage id="chooseLevel" />
            </div>
            <DrawerClose
              asChild
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <div className="flex size-32 shrink-0 items-center justify-center rounded-full bg-gray">
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
          </div>
          <div className="flex-1 overflow-y-auto [scrollbar-width:thin]">
            <LevelsList
              onChange={() => {
                setIsOpen(false)
              }}
            />
            {isClevel && (
              <div className="px-32 pt-24 text-14 text-gray4">
                <FormattedMessage id="ClevelInfo" />
              </div>
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

interface LevelsListProps {
  onChange: () => void
}

export function LevelsList({ onChange }: LevelsListProps) {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const levels = state.programTree.map(
    (x) => x.level,
  ) as (keyof typeof LEVELS_MAP)[]

  return (
    <div className="mt-28 flex flex-col gap-8 px-16">
      {levels.map((level) => {
        const title = LEVELS_MAP[level].title
        const subtitle = LEVELS_MAP[level].subtitle
        return (
          <div
            key={title}
            className="flex cursor-pointer items-center gap-8 rounded-12 bg-gray-light px-12 py-16"
            onClick={() => {
              store.setSelectedLevel(level)
              onChange()
            }}
          >
            <div className="flex size-20 items-center justify-center rounded-full border-2 border-main bg-white">
              {state.selectedLevel === level && (
                <div className="size-12 rounded-full bg-main"></div>
              )}
            </div>

            <div className="flex flex-col items-start justify-between text-16 font-bold">
              <h3>
                <FormattedMessage id={title as AppMessageKeys} />
              </h3>
              <p className="text-14 text-gray5">
                <FormattedMessage
                  id="Approximately"
                  values={{ level: subtitle }}
                />
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface TopicCard {
  title: string
  index: number
  lessonsAmount: number
  isActive?: boolean
  onClick: () => void
}

function TopicCard({
  title,
  index,
  lessonsAmount,
  isActive,
  onClick,
}: TopicCard) {
  return (
    <div
      className={cn(
        'flex h-200 min-w-160 max-w-160 cursor-pointer flex-col overflow-hidden rounded-18 bg-gray-light p-12',
        isActive && 'bg-active-topic-card shadow-active-topic-card',
      )}
      onClick={onClick}
    >
      <img
        src={`/images/program/topics/topic-${index}.png`}
        className="size-60"
      />

      <div
        className={cn(
          'mt-10 font-nunito-10-semicondensed text-12 font-extrabold uppercase',
          isActive ? 'text-topic-card-header-active' : 'text-topic-card-header',
        )}
      >
        <FormattedMessage id="Topic" />
      </div>

      <div
        className={cn(
          'mt-4 line-clamp-2 overflow-hidden text-ellipsis break-words text-20 font-bold capitalize leading-6',
          isActive ? 'text-white' : 'text-black',
        )}
      >
        {title}
      </div>

      <div
        className={cn(
          'mt-auto font-nunito-10-semicondensed text-14 font-medium opacity-50',
          isActive ? 'text-white' : 'text-black',
        )}
      >
        {lessonsAmount}{' '}
        <FormattedMessage
          id="lessonsPlural"
          values={{ count: lessonsAmount }}
        />
      </div>
    </div>
  )
}

function TopicsList() {
  const store = useProgramStore()
  const state = useSnapshot(store.state)

  const topics =
    state.programTree.find((x) => x.level === state.selectedLevel)?.items ?? []

  return (
    <div
      className={cn(
        'no-scrollbar xl:yes-scrollbar  flex gap-16 overflow-auto bg-white px-16 pb-16',
      )}
    >
      {topics.map((x, i) => {
        const lessonsAmount = x.items
          .map((x) => x.items.length)
          .reduce((acc, amount) => acc + amount, 0)

        return (
          <TopicCard
            key={x.program}
            index={i + 1}
            lessonsAmount={lessonsAmount}
            isActive={state.selectedProgram === x.program}
            title={x.program}
            onClick={() => {
              store.setSelectedProgram(x.program)
            }}
          />
        )
      })}
    </div>
  )
}

interface UnitHeaderProps {
  title: string
}

function UnitHeader({ title }: UnitHeaderProps) {
  return (
    <div className="px-16">
      <div className="text-24 font-extrabold capitalize text-black">
        <FormattedMessage id="unit" />
      </div>

      <div className="overflow-hidden text-ellipsis text-nowrap text-18 font-extrabold capitalize leading-none text-black">
        {title}
      </div>
    </div>
  )
}

function Units() {
  const store = useProgramStore()
  const selectedCycles = useSnapshot(store.state).selectedCycles

  return (
    <div className="mx-auto my-0 mb-100 w-full bg-white p-16">
      {selectedCycles.map((cycle) => {
        return (
          <Unit key={cycle.index} title={cycle.title} lessons={cycle.items} />
        )
      })}
    </div>
  )
}

interface UnitProps {
  title: string
  lessons: readonly ProgramItem[]
}

function Unit({ title, lessons }: UnitProps) {
  return (
    <div>
      <UnitHeader title={title} />

      <div className="mt-12 flex flex-wrap gap-16 msm:flex-col">
        {sortBy(lessons, (x) => x.order).map((lesson, i) => {
          return <LessonWithDrawer lesson={lesson} key={lesson.id} index={i} />
        })}
      </div>
    </div>
  )
}

interface LessonProps {
  title: string
  type_name: string
  tag: string
  id: number
  index: number
  type: string
  isSelected?: boolean
  isCompleted?: boolean
  inProgress?: boolean
  isFirstLesson: boolean
  order: number
}

function Lesson(props: LessonProps) {
  const store = useProgramStore()

  const lessonTypeIcons: Record<string, string> = {
    'Warm Up': '/images/program/lessons/human.png',
    Grammar1: '/images/program/lessons/grammar.png',
    Reading: '/images/program/lessons/reading.png',
    Grammar2: '/images/program/lessons/grammar.png',
    Listening: '/images/program/lessons/listening.png',
    Vocabulary1: '/images/program/lessons/vocab.png',
    Vocabulary2: '/images/program/lessons/vocab.png',
    'Role play': '/images/program/lessons/roleplay.png',
    Challenges: '/images/program/lessons/roleplay.png',
  }

  if (props.isFirstLesson && props.order === 1) {
    return (
      <div className="relative h-[240px] w-full max-w-400 shrink-0 rounded-18 bg-gray-light p-18 msm:max-w-full">
        <img
          className="absolute bottom-8 right-14 h-[176px] w-[179px]"
          src="/images/program/start-lesson.png"
          alt=""
        />
        <div className="relative flex h-full flex-col text-start">
          <div className="text-14 font-extrabold capitalize text-black opacity-30">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black">
            {props.title}
          </div>

          <LinkButton
            to={urls.lesson(props.tag)}
            size="custom"
            bg="black-gradient"
            className="mt-auto h-44 max-w-[140px] text-16 font-bold"
          >
            <FormattedMessage id="Start lesson" />
          </LinkButton>
        </div>
      </div>
    )
  }

  return (
    <div
      className="relative flex w-full max-w-400 cursor-pointer items-baseline rounded-18 bg-gray-light p-16 msm:max-w-full"
      onClick={() => {
        store.setSelectedLesson(props.id)
      }}
    >
      <div className="absolute right-10 top-10 text-12 font-semibold opacity-40">
        {props.type_name}
      </div>

      <div className="flex w-full items-center gap-12">
        <div
          className={cn(
            'relative flex size-80 shrink-0 items-center justify-center rounded-full border-4 p-6',
            props.isCompleted
              ? 'border-green bg-green-40'
              : 'border-lesson-icon-border',
          )}
        >
          <div
            className={cn(
              'flex size-full items-center justify-center overflow-hidden rounded-full',
              props.isCompleted ? 'bg-green-40' : 'bg-white',
            )}
          >
            <img className="w-2/3" src={lessonTypeIcons[props.type]} alt="" />
          </div>

          {props.isCompleted && (
            <div className="absolute -bottom-2 -left-2 flex size-24 items-center justify-center rounded-full border-2 border-white bg-green">
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.50033L2.48217 6.53037C2.94672 7.16663 3.90024 7.15427 4.34816 6.50616L8.15346 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>

        <div className="text-start">
          <div className="text-14 font-extrabold capitalize text-black opacity-30">
            <FormattedMessage id="Lesson" /> {props.index}
          </div>

          <div className="mt-2 line-clamp-2 overflow-hidden text-ellipsis break-words text-18 font-bold leading-5 text-black">
            {props.title}
          </div>
        </div>
      </div>
    </div>
  )
}
