import { proxy } from 'valtio'
import { IFilteredMetrics } from '../../shared/lib/utils.ts'
import { PracticeApi } from '../../shared/api/practiceApi.ts'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { ChatApi } from 'src/shared/api/chatApi.ts'
import { PracticeHintType } from 'src/shared/api/vocabApi.ts'

interface State {
  programs: IFilteredMetrics | []
  words: {
    loading: boolean
    count: number | null
  }
  stat: { new: number; weak: number; strong: number; sum: number } | null
  hints: PracticeHintType[] | null
}

export class PracticeStore {
  state: State

  constructor(
    private practiceApi: PracticeApi,
    private chatApi: ChatApi,
  ) {
    this.state = proxy<State>({
      words: {
        loading: false,
        count: null,
      },
      stat: null,
      programs: [],
      hints: null,
    })
  }

  async getPrograms() {
    return await this.practiceApi.getPracticePrograms()
  }

  async getPracticeInfo() {
    try {
      this.state.words.loading = true
      this.state.words.count = await this.practiceApi.getCount()
      const account = await this.chatApi.getAccount()
      if (account.session_metadata?.hints) {
        this.state.hints = account.session_metadata.hints
      }
      const statistics = await this.practiceApi.getVocabStat()

      this.state.stat = {
        ...statistics,
        sum: statistics.new + statistics.strong + statistics.weak,
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.state.words.loading = false
    }
  }

  deleteHint(hint: PracticeHintType) {
    const hintsArr = this.state.hints
    if (!hintsArr) return
    if (hintsArr.length === 1 && hintsArr.includes(hint)) {
      this.state.hints = null
      void this.updateSessionMetadata(null)
    }
  }

  async updateSessionMetadata(hints: PracticeHintType[] | null) {
    try {
      const account = await this.chatApi.getAccount()
      const metadata = account.session_metadata
        ? { ...account.session_metadata }
        : null
      if (metadata === null) {
        return
      }
      if (hints === null && metadata.hints) {
        delete metadata.hints
        return await this.chatApi.patchAccount({
          session_metadata: { ...metadata },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const PracticeStoreContext = createStoreContext<PracticeStore>()

export function usePracticeStore() {
  return useStoreContext(PracticeStoreContext)
}
