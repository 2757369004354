import React, { Fragment, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import {
  ChatMessageWithIndex,
  ChatStore,
  isArticle,
  isExercise,
  isImage,
  MessageContent,
} from './chatStore.ts'
import { useSnapshot } from 'valtio/react'
import { ChatAudioRecordStore } from './chatAudioRecordStore.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { classed } from '@tw-classed/react'
import {
  cn,
  emptyFn,
  formatTimer,
  getLast,
  removeTextTag,
  splitBy,
  useMount,
} from '../../shared/lib/utils.ts'
import { DotsAnimation, Spinner } from '../../shared/ui/spinner.tsx'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { AnimatePresence, motion } from 'framer-motion'
import { match, P } from 'ts-pattern'
import {
  createStoreContext,
  useIsMobile,
  useLazyRef,
  useStoreContext,
} from '../../shared/lib/hooks.ts'
import { ClickableWords } from '../../shared/ui/ClickableWords/ClickableWords.tsx'
import { Badge } from '../../shared/ui/card/card.tsx'
import { MatchWords } from '../exercises/matchWords/matchWords.tsx'
import { Vocabulary } from '../../shared/api/chatApi.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { ErrorBoundary } from '@sentry/react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../../shared/ui/drawer/drawer.tsx'
import { isWebDomain } from '../../shared/urls.ts'
import { inputClassBase } from '../../shared/ui/textInput/textInput.tsx'
import { proxy } from 'valtio'
import { WithTooltip } from '../../shared/ui/tooltip/tooltip.tsx'
import { ImageHideBeforeLoad } from '../../shared/ui/images/images.tsx'
import { WriteTheGapss } from '../exercises/writeTheGaps.tsx'
import { VocabularyContainer } from './demoChat.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { convertToId } from '../../shared/lib/stringUtils.ts'
import { Soundwave } from '../../shared/ui/soundwave/soundwave.tsx'
import { useAppModule } from '../appContext.ts'
import { BuyTrial } from '../programs/buyTrial.tsx'
import { TrueOrFalseExercise } from '../exercises/trueOrFalse/TrueOrFalseExercise.tsx'
import { FillTheGaps } from '../exercises/fillTheGaps/fillTheGaps.tsx'
import { AnswerStatusDrawer } from '../exercises/answerStatusDrawer.tsx'
import { ListeningExercise } from '../exercises/listeningExercise/listeningExercise.tsx'
import { LessonIsCompleted } from '../lessonCompleted/lessonCompleted.tsx'
import { LessonStatistics } from '../lessonStatistics/lessonStatistics.tsx'
import { AppHeaderNavigationButton } from '../appHeader/appHeader.tsx'

export function StageProgressBlockMobile() {
  const store = useStores().chatStore
  const chatState = useSnapshot(store.state)
  const isLessonCompleted = chatState.lessonCompleted
  const isMobile = useIsMobile()
  const isReadonly = chatState.viewMode === 'readonly'
  const isDemo = chatState.isDemo
  const getIsNavButton = () => {
    if (isReadonly) return true
    return !isDemo && !isLessonCompleted && isMobile
  }
  return (
    <div className="fixed left-0 top-0 z-20 flex h-100 w-full items-center bg-demo-mobile-header px-16 backdrop-blur-4 xl:static xl:bg-white xl:backdrop-blur-0">
      <div className="flex items-center">
        {getIsNavButton() && <AppHeaderNavigationButton />}
      </div>
      <div className="ml-16 flex-1 text-start text-18 font-bold">
        {chatState.isDemo ? (
          <FormattedMessage id="Demo lesson" />
        ) : (
          !!chatState.program && chatState.program.lesson_subject
        )}
      </div>
      {chatState.lessonType === 'lesson' && (
        <div
          className={cn(
            'xl:hidden',
            chatState.currentHint === 'allWords' && 'z-40',
          )}
        >
          <VocabularyDrawer />
        </div>
      )}
      <HeaderHintView />
    </div>
  )
}

export function messageStageElementId(stage: string | undefined) {
  return stage ? `__message-stage-${convertToId(stage)}` : ''
}

export const ChatStoreContext = createStoreContext<{
  chatStore: ChatStore
  recordStore: ChatAudioRecordStore
}>()

export function useStores() {
  return useStoreContext(ChatStoreContext)
}

const MessageText = classed.div(
  'relative flex flex-col gap-12 overflow-hidden whitespace-pre-wrap px-16 py-12 text-14 font-normal text-black',
  {
    variants: {
      is_ai: {
        false: 'ml-6 min-w-230 rounded-l-18 rounded-tr-18 bg-user-message',
        true: 'mr-8 w-full rounded-r-18 rounded-tl-18 bg-edman-message',
      },
    },
  },
)

interface MessageViewProps {
  message: ChatMessageWithIndex
  loading: boolean
  showText: boolean
  history?: boolean
  className?: string
}

const MessageButton = classed.button(
  'flex size-28 items-center justify-center rounded-full bg-edman-message text-message-button',
)

function MessageButtons(props: { message: ChatMessageWithIndex }) {
  const store = useStores().chatStore
  const intl = useIntl()
  const translationLoading =
    props.message.showTranslation && !props.message.translation

  return (
    <div className="flex shrink-0 items-center gap-8">
      <PlayButton hint={false} messageId={props.message.id} />
      <WithTooltip tooltip={intl.formatMessage({ id: 'Translate' })}>
        {translationLoading ? (
          <Spinner />
        ) : (
          <MessageButton
            type="button"
            onClick={() => {
              if (!store.state.loading) {
                void store.toggleMessageTranslate(props.message.id)
              }
            }}
          >
            <Icon
              size="little"
              iconName="translate"
              className={cn(
                'cursor-pointer transition-colors',
                props.message.showTranslation && 'text-main',
              )}
            />
          </MessageButton>
        )}
      </WithTooltip>
    </div>
  )
}

function PlayButton(props: { hint: boolean; messageId: number }) {
  const store = useStores().chatStore
  const intl = useIntl()
  return (
    <WithTooltip tooltip={intl.formatMessage({ id: 'Play again' })}>
      <MessageButton
        className="pl-2"
        type="button"
        onClick={() => {
          if (!store.state.loading) {
            void store.play(props.messageId, true, props.hint)
          }
        }}
      >
        <Icon
          size="xs"
          iconName="playMessage"
          className={cn('cursor-pointer')}
        />
      </MessageButton>
    </WithTooltip>
  )
}

function PlayProgress(props: { messageId: number }) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const value =
    props.messageId != state.playingMessage?.messageId
      ? 0
      : state.playingMessage.progress * 100
  return <ProgressBar value={value} />
}

function MessageView(props: MessageViewProps) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const showText = !props.message.is_ai || props.showText
  const selectedWords = state.selectedWords
  const messageId = props.message.id

  const messageText = props.message.text
  const translation =
    props.message.translation && removeTextTag(props.message.translation)
  return (
    <div
      id={messageStageElementId(props.message.stage)}
      className={cn(
        'flex',
        props.message.is_ai ? 'items-end' : 'justify-end',
        props.className,
      )}
    >
      {!props.message.is_ai && <AiCommentButton message={props.message} />}
      <MessageText is_ai={props.message.is_ai}>
        {props.message.is_ai && (
          <img
            src="/images/edman.jpg"
            className="hidden size-40 shrink-0 overflow-hidden rounded-full sm:inline"
            alt=""
          />
        )}
        {props.loading ? (
          <>
            <div
              className={cn(
                'flex',
                !props.message.is_ai && 'w-full justify-end',
              )}
            >
              <DotsAnimation />
            </div>
            {props.message.is_ai && state.responseLoadingText && (
              <div className="mt-4">{state.responseLoadingText}</div>
            )}
          </>
        ) : (
          <>
            {showText ? (
              props.message.is_ai ? (
                <div className="flex-1">
                  <ClickableWords
                    key={messageText}
                    animateSpeed={
                      state.lastMessageId == props.message.id &&
                      state.viewMode !== 'readonly'
                        ? 'fast'
                        : undefined
                    }
                    disabled={false}
                    className="flex-1"
                    selectedWords={selectedWords}
                    onClick={(word, add) => {
                      store.handleOpenAddDrawer(word, add, messageId)
                    }}
                  >
                    {messageText}
                  </ClickableWords>
                  <AnimatePresence>
                    {props.message.showTranslation && translation && (
                      <motion.div
                        className="overflow-hidden text-light"
                        initial={{ opacity: 0, height: '0' }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: '0' }}
                      >
                        <div className="pt-20">{translation}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <div className="w-full text-right">{props.message.text}</div>
              )
            ) : (
              <PlayProgress messageId={props.message.id} />
            )}
          </>
        )}

        <AnimatePresence>
          {props.message.showAiComment && <AiComment message={props.message} />}
        </AnimatePresence>
      </MessageText>

      {props.message.is_ai && !props.history && (
        <MessageButtons message={props.message} />
      )}
    </div>
  )
}

function AiCommentButton(props: { message: ChatMessageWithIndex }) {
  const store = useStores().chatStore
  const comment = props.message.ai_comment
  if (!comment) {
    return null
  }
  const hasComments = comment.length > 0
  return (
    <button
      className={cn(
        'self-center rounded-full transition-colors',
        hasComments
          ? props.message.showAiComment
            ? 'bg-main'
            : 'bg-ai-button-inactive'
          : 'bg-none',
        hasComments ? 'cursor-pointer' : 'cursor-default',
      )}
      onClick={() => {
        if (hasComments) {
          store.toggleMessageAiComment(props.message.id)
        }
      }}
    >
      <Icon iconName={hasComments ? 'asterisk' : 'checkGreen'} size="xsm" />
    </button>
  )
}

function AiComment(props: { message: ChatMessageWithIndex }) {
  const duration = 0.3
  return (
    <div className="-mx-16 -mb-12 divide-y bg-ai-comment-active text-black shadow-drop-inset-top">
      <motion.div
        className="mx-16"
        transition={{ opacity: { delay: duration, duration } }}
        initial={{ opacity: 0, height: '0', width: '0' }}
        animate={{ opacity: 1, height: 'auto', width: 'auto' }}
        exit={{ opacity: 0, height: '0', width: '0' }}
      >
        {props.message.ai_comment?.map((x, i) => (
          <div className="flex flex-col gap-8 py-8" key={i}>
            <div>{x.correct}</div>
            <div>{x.rule}</div>
          </div>
        ))}
      </motion.div>
    </div>
  )
}

const MessageListContainer = classed.div(
  'relative mt-auto flex w-full flex-col gap-12 pt-60',
)

function MessageList() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const bottomRef = useRef<HTMLDivElement>(null)
  const isReadonlyView = state.viewMode === 'readonly'
  useEffect(() => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }, [
    state.messages.length,
    state.loading,
    state.lastMessage.reply_hint,
    state.lastMessage.showHint,
  ])
  useMount(() => {
    bottomRef.current?.scrollIntoView()
  })
  return (
    <div
      className={cn(
        'flex w-full flex-1 overflow-y-auto bg-white px-16 pt-40 [scrollbar-width:thin]',
        isReadonlyView && 'pb-24 pt-100',
      )}
    >
      <MessageListContainer>
        <DemoStart />
        {state.messages.map((x) => {
          const content = x.content && (
            <MessageContentView
              completed={state.lastMessageId != x.id}
              content={x.content}
              messageId={x.id}
            />
          )
          return (
            <Fragment key={x.id}>
              <MessageView
                showText={state.showMessageTexts}
                key={x.index}
                message={x}
                loading={false}
              />
              {content}
            </Fragment>
          )
        })}
        {state.loadingMessage ? (
          <MessageView
            showText={state.showMessageTexts}
            key="loading"
            message={state.loadingMessage}
            loading={true}
          />
        ) : (
          <MessageHint />
        )}
        <div ref={bottomRef}></div>
      </MessageListContainer>
      <LessonIsCompletedBlock />
      <LessonStatisticsBlock />
    </div>
  )
}

function MessageHint() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const lastMessage = getLast(state.messages)
  if (
    state.lessonCompleted ||
    isExercise(state.lastMessage.content) ||
    !lastMessage
  ) {
    return null
  }
  const loading = lastMessage.showHint && !lastMessage.reply_hint
  const title = (
    <>
      {loading ? <Spinner /> : <Icon iconName="bulb" />}
      <FormattedMessage id="chat.hint" />
    </>
  )
  if (lastMessage.reply_hint && lastMessage.showHint) {
    return (
      <div className="relative ml-auto max-w-300 rounded-l-12 rounded-tr-12 bg-warning p-16 text-14 font-semibold">
        <div className="mb-8 flex items-center gap-4 text-16 font-bold text-warning-dark">
          {title}
        </div>
        <div>
          <ClickableWords
            disabled={false}
            selectedWords={state.selectedWords}
            onClick={(word, add) => {
              store.handleOpenAddDrawer(word, add, lastMessage.id)
            }}
          >
            {lastMessage.reply_hint.text}
          </ClickableWords>
        </div>
        <div className="mt-12 text-gray-dark">
          {lastMessage.reply_hint.translation}
        </div>

        <div className="absolute bottom-4 right-4 flex items-center gap-8">
          <PlayButton hint={true} messageId={lastMessage.id} />
        </div>
      </div>
    )
  }
  return (
    <Button
      onClick={() => {
        void store.showMessageHint()
      }}
      bg="custom"
      rounded="full"
      className="ml-auto w-fit gap-4 bg-white text-warning-dark shadow-button"
      size="little"
    >
      {title}
    </Button>
  )
}

const DemoStart = () => {
  const isMobile = useIsMobile()
  const state = useSnapshot(useStores().chatStore.state)
  if (!state.isDemo || !isMobile) {
    return null
  }
  return (
    <div className="my-48 flex flex-col items-center">
      <div className="my-20 text-center text-24 font-bold text-black">
        <FormattedMessage id="chat.demoStart" />
      </div>
    </div>
  )
}

const LessonStatisticsBlock = React.memo(() => {
  const store = useStores().chatStore
  const { statistics } = useSnapshot(store.state)
  if (statistics.isLoading)
    return (
      <div className="fixed left-0 top-0 z-30 flex h-[100dvh] w-full items-center justify-center bg-white">
        <Spinner />
      </div>
    )
  if (statistics.isStatPage) {
    return <LessonStatistics store={store} />
  }
  return null
})

LessonStatisticsBlock.displayName = 'LessonStatisticsBlock'

const LessonIsCompletedBlock = React.memo(() => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)

  if (state.statistics.isLessonCompletedPage && !state.loading) {
    return <LessonIsCompleted store={store} />
  }
  return null
})

LessonIsCompletedBlock.displayName = 'ContentView'

const MessageContentContainer = classed.div('w-fit rounded-12 bg-white', {
  variants: {
    image: {
      true: 'overflow-hidden',
      false: 'p-16',
    },
  },
})

function MessageContentView(props: {
  content: MessageContent
  completed: boolean
  messageId: number
}) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const hasImage = isImage(props.content)
  const content = match(props.content)
    .with({ image: P._ }, (x) => (
      <ImageHideBeforeLoad className="max-h-[40vh]" src={x.image} />
    ))
    .with({ article: P._ }, (x) => (
      <Article
        title={x.article.title}
        body={x.article.body}
        messageId={props.messageId}
      />
    ))
    .with({ text: P._ }, (x) => {
      return (
        <div className="whitespace-pre-wrap">
          <ClickableWords
            animateSpeed="fast"
            disabled={false}
            selectedWords={state.selectedWords}
            onClick={(word, add) => {
              store.handleOpenAddDrawer(word, add, props.messageId)
            }}
          >
            {x.text}
          </ClickableWords>
        </div>
      )
    })
    .with({ matchWords: P._ }, (x) => (
      <MatchWords
        completed={props.completed}
        words={x.matchWords}
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
      />
    ))
    .with({ writeTheGaps: P._ }, (x) => (
      <WriteTheGapss
        completed={props.completed}
        texts={x.writeTheGaps}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .with({ fillTheGaps: P._ }, (x) => (
      <FillTheGaps
        completed={props.completed}
        texts={x.fillTheGaps}
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
      />
    ))
    .with({ trueOrFalse: P._ }, (x) => (
      <TrueOrFalseExercise
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
        questions={x.trueOrFalse}
      />
    ))
    .with({ listening: P._ }, (x) => (
      <ListeningExercise
        onComplete={(x, y, payload) => {
          store.onExerciseComplete(x, y, payload)
        }}
        completed={props.completed}
        questions={x.listening}
      />
    ))
    .exhaustive()
  if (isExercise(props.content) || isArticle(props.content)) {
    return content
  }
  return (
    <ErrorBoundary
      fallback={
        <span className="text-alert">
          <FormattedMessage id="contentError" />
        </span>
      }
    >
      <MessageContentContainer image={hasImage}>
        {content}
      </MessageContentContainer>
    </ErrorBoundary>
  )
}

export const VocabularyHeader = React.memo((props: { className?: string }) => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const [newWords, topicWords] = splitBy(
    state.vocabulary,
    (x) => x.is_user_added,
  )

  return (
    <div className={cn('mb-16 gap-24 text-14 font-extrabold', props.className)}>
      {!state.isDemo && (
        <div className="flex items-center gap-8">
          <Badge size="small" bg="orange">
            {topicWords.length}
          </Badge>
          <FormattedMessage id="Topic words" />
        </div>
      )}
      <div className="flex items-center gap-8">
        <Badge size="small" bg="purple-light">
          {newWords.length}
        </Badge>
        <FormattedMessage id="My words" />
      </div>
    </div>
  )
})

VocabularyHeader.displayName = 'VocabularyHeader'

interface VocabularyState {
  openedTopicWordIndex: number | null
  openedMyWordIndex: number | null
}

type VocabType = 'myWords' | 'topicWords'

const CircleDot = (props: { dot: boolean }) => {
  return (
    <div className="flex size-20 items-center justify-center rounded-full border-2 border-main">
      {props.dot && (
        <div className="size-12 rounded-full bg-main animate-in fade-in"></div>
      )}
    </div>
  )
}

const WordBlock = React.memo(
  (props: {
    vocabulary: Vocabulary
    state: VocabularyState
    opened: boolean
    index: number
    type: VocabType
  }) => {
    const x = props.vocabulary
    const opened = props.opened

    const handleToggleWord = () => {
      if (props.type === 'myWords') {
        props.state.openedMyWordIndex =
          Number.isInteger(props.index) &&
          props.index === props.state.openedMyWordIndex
            ? null
            : props.index
      } else {
        props.state.openedTopicWordIndex =
          Number.isInteger(props.index) &&
          props.index === props.state.openedTopicWordIndex
            ? null
            : props.index
      }
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleToggleWord}
        className={cn(
          'flex w-full cursor-pointer gap-8 overflow-hidden rounded-12 bg-gray-light p-16',
        )}
      >
        <div className="flex h-24 items-center">
          <CircleDot dot={opened} />
        </div>
        <WordBlockContent opened={opened} word={x} />
      </motion.div>
    )
  },
)
WordBlock.displayName = 'WordBlock'

export function VocabularyView() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const intl = useIntl()
  const lastAddWordVocab = state.lastAddVocabWord
  const [myWords, topicWords] = splitBy(
    state.vocabulary,
    (x) => x.is_user_added,
  )

  const getInitValue = () => {
    const wordIndex = myWords.findIndex(
      (el) => el.word_original === lastAddWordVocab,
    )
    if (wordIndex === -1) return null
    return wordIndex
  }
  const stateProxy = useLazyRef(() =>
    proxy<VocabularyState>({
      openedTopicWordIndex: null,
      openedMyWordIndex: getInitValue(),
    }),
  ).current
  const localState = useSnapshot(stateProxy)

  const textId = state.isDemo
    ? 'vocabularyPlaceholderDemo'
    : 'vocabularyPlaceholder'
  const description = state.vocabulary.length == 0 && (
    <div>
      <FormattedMessage id={textId} />
    </div>
  )

  const blocks = (words: Vocabulary[], type: VocabType) => (
    <>
      {words.map((x, i) => {
        const opened =
          type === 'myWords'
            ? localState.openedMyWordIndex === i
            : localState.openedTopicWordIndex === i
        return (
          <WordBlock
            opened={opened}
            key={`${x.id}-${i}`}
            vocabulary={x}
            state={stateProxy}
            index={i}
            type={type}
          />
        )
      })}
    </>
  )

  const header = (text: string) => (
    <div className="pl-12 font-nunito-7-semicondensed text-16 font-bold text-light">
      {text}
    </div>
  )
  return (
    <VocabularyContainer>
      <div className="flex flex-col">
        {state.vocabulary.length == 0 ? (
          description
        ) : (
          <>
            <div className="mb-24 flex flex-col gap-8">
              {header(
                `${intl.formatMessage({ id: 'Topic words' })} (${
                  topicWords.length
                })`,
              )}
              {blocks(topicWords, 'topicWords')}
            </div>
            <div className="flex flex-col gap-8">
              {header(
                `${intl.formatMessage({ id: 'My words' })}  (${
                  myWords.length
                })`,
              )}
              {blocks(myWords, 'myWords')}
            </div>
          </>
        )}
      </div>
    </VocabularyContainer>
  )
}

export function VocabularyColumn() {
  return (
    <div className="hidden size-full w-400 flex-col gap-24 pb-60 sm:flex">
      <div className="overflow-y-auto rounded-6 [scrollbar-width:thin] sm:shadow-card">
        <VocabularyView />
      </div>
      {isWebDomain && <BuyTrial vertical={true} />}
    </div>
  )
}

function WordBlockContent(props: { opened: boolean; word: Vocabulary }) {
  const store = useStores().chatStore
  return (
    <div className="w-full">
      <div className="flex items-center gap-8 text-16 leading-6">
        <span className="font-nunito-7-semicondensed text-16 font-bold">
          {props.word.word_normal}
        </span>
        {props.opened && props.word.explain && (
          <>
            <Icon
              size="sm"
              iconName="play"
              className={cn('cursor-pointer animate-in fade-in')}
              onClick={(e) => {
                e.stopPropagation()
                store.playWord(props.word.word_normal)
              }}
            />
            <Icon
              size="sm"
              iconName="delete"
              className={cn(
                'ml-auto cursor-pointer text-light animate-in fade-in',
              )}
              onClick={(e) => {
                e.stopPropagation()
                void store.removeWord(props.word.id)
              }}
            />
          </>
        )}
      </div>
      <AnimatePresence>
        {props.opened && (
          <motion.div
            className="overflow-hidden"
            initial={{ opacity: 0, height: '0' }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: '0' }}
          >
            {props.word.explain ? (
              <>
                <div className="mt-8 overflow-hidden">
                  <div>{props.word.explain}</div>
                </div>
                <div className="mt-12">
                  <div>
                    <div className="text-14 font-extrabold text-light">
                      <FormattedMessage id="Translation:" />
                    </div>
                    <div className="text-16 font-bold">
                      {props.word.translation}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Spinner size="small" className="my-8" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const ChatViewContainer = classed.div(
  'relative flex w-full flex-col overflow-hidden sm:max-w-800 xl:rounded-t-24 xl:bg-white xl:shadow-container',
  {
    variants: {
      loading: {
        true: 'items-center justify-center',
      },
    },
  },
)

export function ChatView(props: { className?: string }) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const isLoading = state.loading == 'chat'
  return (
    <ChatViewContainer className={props.className} loading={isLoading}>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <>
          {state.showChatBlock && (
            <>
              <StageProgressBlockMobile />
              <MessageList />
              <Footer />
            </>
          )}
        </>
      )}
      <AnswerStatusDrawer />
    </ChatViewContainer>
  )
}

export function AddWordDrawer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  return (
    <Drawer
      open={state.addWordDrawer.isOpen}
      onOpenChange={(open) => {
        if (!open) {
          store.handleCloseAddDrawer()
        }
      }}
    >
      <DrawerContent
        direction="bottom"
        className="mx-auto mt-24 flex h-auto max-w-800 flex-col rounded-t bg-white"
      >
        <DrawerBar />
        <div className="flex h-auto flex-col">
          <div className="flex flex-col  px-16 pt-16">
            <h3 className="text-22 font-bold">
              <FormattedMessage
                id={state.addWordDrawer.shouldAdd ? 'addWord' : 'removeWord'}
              />
            </h3>
            <p className="mt-14 text-14">
              <FormattedMessage
                id={
                  state.addWordDrawer.shouldAdd
                    ? 'addCurrentWord'
                    : 'removeCurrentWord'
                }
                values={{ word: `"${state.addWordDrawer.word}"` }}
              />
            </p>
            <DrawerClose asChild>
              <div className="absolute right-16 flex size-32 items-center justify-center rounded-full bg-gray">
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
            <Button
              rounded="full"
              size="large"
              bg="blue-gradient"
              onClick={() => {
                void store.handleWord()
                store.handleCloseAddDrawer()
              }}
              className="mt-48"
            >
              <FormattedMessage
                id={state.addWordDrawer.shouldAdd ? 'add' : 'chat.removeWord'}
              />
            </Button>

            <Button
              bg="gray-gradient"
              rounded="full"
              size="large"
              onClick={() => {
                store.handleCloseAddDrawer()
              }}
              className="mb-32 mt-12"
            >
              <FormattedMessage id="cancel" />
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const HintWrapper = classed.div(
  'flex h-[calc(100%-100px)] w-full flex-col text-white',
)

export function ClickWordBlock() {
  return (
    <HintWrapper className="items-center justify-center px-16 text-center">
      <h3 className="text-24 font-extrabold">
        <FormattedMessage id="hints.clickWordTitle" />
      </h3>
      <p className="mt-12 text-16">
        <FormattedMessage
          id="hints.clickWordText"
          values={{
            click: (
              <span className="font-black">
                <FormattedMessage id="click" />
              </span>
            ),
          }}
        />
      </p>
      <img src="/images/hints/clickWordHand.svg" className="mt-32 size-fit" />
    </HintWrapper>
  )
}

export function AllWordsBlock() {
  return (
    <HintWrapper className="justify-start pl-[20%] pr-32">
      <div className="flex items-end justify-center gap-16 text-24 font-extrabold">
        <h3>
          <FormattedMessage id="hints.allWordsTitle" />
        </h3>
        <img src="/images/hints/topRightArrow.svg" className="mb-16 size-fit" />
      </div>
      <p className="mt-12 text-16">
        <FormattedMessage id="hints.allWordsText" />
      </p>
    </HintWrapper>
  )
}

export function HintView() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)

  const blocks = {
    clickWord: { el: <ClickWordBlock /> },
    allWords: { el: <AllWordsBlock /> },
  }

  return (
    <AnimatePresence>
      {state.shouldViewHint && state.currentHint && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-30 mt-[100px] size-full bg-black-80"
          onClick={() => {
            store.setShouldViewHint(false)
          }}
        >
          {blocks[state.currentHint].el}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function HeaderHintView() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)

  return (
    <AnimatePresence>
      {state.shouldViewHint && state.currentHint && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute left-0 size-full bg-black-80 xl:bg-white"
          onClick={() => {
            store.setShouldViewHint(false)
          }}
        />
      )}
    </AnimatePresence>
  )
}

const states = [
  'idle',
  'recording',
  'cancel',
  'loading_start',
  'loading',
  'loading_to_idle',
  'disabled',
  'disabled_to_idle',
] as const

function RecordButtonIcon() {
  const store = useStores().chatStore
  const recordStore = useStores().recordStore
  const state = useSnapshot(store.state)
  const recordState = useSnapshot(recordStore.state)

  const animationState = state.chatInputUnavailable
    ? 'disabled'
    : state.sendButtonDisabled
    ? 'loading_start'
    : recordState.recording
    ? 'recording'
    : 'idle'

  const { rive, RiveComponent } = useRive({
    src: '/animations/microphone.riv',
    stateMachines: 'State Machine 1',
    animations: 'idle',
    autoplay: true,
  })
  const stateMachineInput = useStateMachineInput(
    rive,
    'State Machine 1',
    'Number 1',
  )
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (stateMachineInput) {
      const getCurrentState = () => states[Number(stateMachineInput.value)]
      const nextState =
        getCurrentState() == 'recording' && animationState == 'idle'
          ? 'cancel'
          : getCurrentState() == 'disabled'
          ? 'disabled_to_idle'
          : animationState == 'idle' && getCurrentState() == 'loading'
          ? 'loading_to_idle'
          : animationState

      stateMachineInput.value = states.indexOf(nextState)
      function transfer(
        from: (typeof states)[number],
        to: (typeof states)[number],
        delay: number,
        cb = emptyFn,
      ) {
        if (getCurrentState() == from) {
          setTimeout(() => {
            if (stateMachineInput && getCurrentState() == from) {
              stateMachineInput.value = states.indexOf(to)
              cb()
            }
          }, delay)
        }
      }
      transfer('loading_start', 'loading', 1000)
      transfer('disabled_to_idle', 'loading_start', 500, () => {
        transfer('loading_start', 'loading', 1000)
      })
    }
  }, [rive, animationState, stateMachineInput])

  return <RiveComponent />
}

const ChatTextInputContainer = motion(
  classed.div('flex w-full items-center gap-12'),
)

export const ChatTextInput = () => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state, { sync: true })
  const recoding = state.loading == 'record'
  const intl = useIntl()

  const recordStore = useStores().recordStore
  const recordState = useSnapshot(recordStore.state)

  const input = (
    <>
      <AnimatePresence initial={false}>
        {!state.showTextInput && <MicRecordBlock />}
      </AnimatePresence>
      <ChatTextInputContainer
        className={cn(
          'rounded-6 border-2 border-text-area-border shadow-inset-text-area',
          state.inputDisabled ? 'bg-gray' : 'bg-white',
        )}
      >
        <TextareaAutosize
          value={
            recoding ? formatTimer(recordState.recordTimer) : state.currentText
          }
          data-testid="chat-input"
          placeholder={intl.formatMessage({ id: 'Message' })}
          disabled={state.inputDisabled}
          autoFocus={state.showTextInput}
          autoComplete="off"
          readOnly={recoding}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              void store.sendCurrentText()
            }
          }}
          onChange={(e) => {
            store.setCurrentText(e.currentTarget.value)
          }}
          maxRows={4}
          className={cn(
            'w-full resize-none rounded-6 bg-transparent px-16 py-8 text-20 leading-tight [scrollbar-width:thin] placeholder:pt-4 placeholder:text-14',
            inputClassBase,
            recoding && 'text-right',
          )}
        />
        <SendButton textInput={true} />
      </ChatTextInputContainer>
    </>
  )

  const completeLessonButton = (
    <Button
      size="extralarge"
      className="w-full"
      rounded="full"
      disabled={!state.lessonCompleted}
      bg="blue-gradient"
      onClick={() => {
        store.goToLessonCompletedPage()
      }}
    >
      <FormattedMessage id="Complete the lesson" />
    </Button>
  )

  return (
    <div className="relative flex h-[148px] shrink-0 items-center bg-white px-16 sm:mx-0">
      <div className="absolute -top-18 left-0 h-20 w-full bg-gradient-to-t from-white from-30% to-transparent"></div>
      {state.lessonCompleted ? (
        completeLessonButton
      ) : !state.isExercise ? (
        input
      ) : (
        <></>
      )}
    </div>
  )
}

function SendButton(props: { textInput: boolean }) {
  const store = useStores().chatStore
  const recordStore = useStores().recordStore
  const state = useSnapshot(store.state, { sync: true })

  const postfix = isImage(getLast(state.messages)?.content) ? 'demo_image' : ''

  const buttonSendId = state.isDemo && postfix ? 'send_' + postfix : undefined

  const sendButton = (
    <Button
      size={props.textInput ? 'medium' : 'large'}
      rounded={props.textInput ? undefined : 'full'}
      bg="blue-gradient-shadow-inset"
      className={cn('mr-4 gap-12', !props.textInput && 'mr-0 w-44 px-0 pr-4')}
      id={buttonSendId}
      disabled={state.sendButtonDisabled}
      onClick={() => {
        if (props.textInput) {
          void store.sendCurrentText()
        } else {
          void recordStore.stopRecording(true)
        }
      }}
    >
      {props.textInput && <FormattedMessage id="Send" />}
      <Icon
        iconName="send"
        className={cn(props.textInput ? '-mr-8' : '-ml-16')}
      />
    </Button>
  )
  const micButton = (
    <button
      id="record-button"
      className="mr-16 text-blue5 disabled:text-gray-dark"
      disabled={state.sendButtonDisabled}
      onClick={() => {
        store.setShowTextInput(false)
      }}
    >
      <Icon iconName="mic" />
    </button>
  )

  return state.currentText || !props.textInput ? sendButton : micButton
}

function Footer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)

  return state.viewMode === 'readonly' ? null : <ChatTextInput />
}

function SoundwaveBlock() {
  const service = useAppModule().streamService
  const store = useStores().chatStore
  return (
    <Soundwave
      className="-mx-24 -mb-12"
      getData={() => {
        return store.state.loading == 'record'
          ? service.getUpdatedAnalyzerData()
          : new Uint8Array()
      }}
    />
  )
}

const ChatControlButton = classed.button(
  'flex size-44 items-center justify-center rounded-full bg-purple-light2 text-purple5 opacity-60 shadow-control-button outline-none active:shadow-none',
)

export function MictoTooltip() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  return (
    <AnimatePresence>
      {state.shouldViewMicroTooltip && !state.sendButtonDisabled && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute -top-12 w-fit whitespace-nowrap text-14 font-bold text-gray5"
        >
          <FormattedMessage id="ClickToRecord" />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function MicRecordBlock() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const recording = state.loading == 'record'
  const recordStore = useStores().recordStore
  const showMic = !state.completed

  useEffect(() => {
    recordStore.reset()
  }, [showMic, state.showHistory, recordStore])
  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className="absolute inset-x-0 bottom-0 mt-24 bg-white px-24 sm:rounded-t"
    >
      <SoundwaveBlock />
      <div className="flex px-20">
        <div className="flex flex-1 items-center">
          {recording ? (
            <RecordTimer />
          ) : (
            <ChatControlButton
              onClick={() => {
                store.toggleMute()
              }}
            >
              <Icon iconName={state.mute ? 'mute' : 'unmute'} />
            </ChatControlButton>
          )}
        </div>
        <div className="relative flex flex-1 justify-center">
          <MictoTooltip />
          <button
            onClick={() => {
              void recordStore.startOrCancelRecording()
            }}
            disabled={state.sendButtonDisabled}
            className={cn(
              'pointer-events-auto size-100 items-center justify-center outline-none',
            )}
          >
            <RecordButtonIcon />
          </button>
        </div>
        <div className="flex flex-1 items-center justify-end">
          {recording ? (
            <SendButton textInput={false} />
          ) : (
            <ChatControlButton
              onClick={() => {
                store.setShowTextInput(true)
              }}
            >
              <Icon iconName="keyboard" />
            </ChatControlButton>
          )}
        </div>
      </div>
    </motion.div>
  )
}

function RecordTimer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const recordStore = useStores().recordStore
  const recordState = useSnapshot(recordStore.state)
  const recoding = state.loading == 'record'
  return recoding ? (
    <motion.div
      className="flex items-center gap-8 text-14 font-semibold text-[#7383AD]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="size-12 animate-pulse rounded-full bg-alert-dark"></div>
      {formatTimer(recordState.recordTimer)}
    </motion.div>
  ) : null
}

interface ProgressBarProps {
  value: number
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className="my-12 h-8 w-full rounded-full bg-light">
      <div
        className="h-8 rounded-full bg-main text-[#7383AD] transition-width duration-100 ease-linear"
        style={{ width: props.value + '%' }}
      ></div>
    </div>
  )
}

export function VocabularyDrawer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const wordCount = state.vocabulary.length

  const handleCloseHint = () => {
    store.setShouldViewHint(false)
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button
          size="small"
          rounded="full"
          bg="blue-gradient-shadow-inset"
          onClick={handleCloseHint}
          className="font-nunito-7-condensed"
        >
          {wordCount}{' '}
          <FormattedMessage id="wordsPlural" values={{ count: wordCount }} />
        </Button>
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
      >
        <DrawerBar />
        <div className="flex h-[95svh] flex-col">
          <div className="flex items-center justify-between px-16">
            <div className="ml-12 text-20 font-bold">
              <FormattedMessage id="words" />
            </div>
            <DrawerClose asChild>
              <div
                className="flex size-32 items-center justify-center rounded-full bg-gray"
                onClick={() => {
                  store.setLastAddVocabWord(null)
                }}
              >
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
          </div>
          <div className="flex-1 overflow-y-auto [scrollbar-width:thin]">
            <VocabularyView />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

function Article(props: { title: string; body: string; messageId: number }) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  return (
    <div className="flex flex-col gap-12 rounded-18 bg-gray-light p-16">
      <div className="text-24 font-extrabold">{props.title}</div>
      <div className="text-16 font-semibold">
        <ClickableWords
          disabled={false}
          selectedWords={state.selectedWords}
          onClick={(word, add) => {
            store.handleOpenAddDrawer(word, add, props.messageId)
          }}
        >
          {props.body}
        </ClickableWords>
      </div>
    </div>
  )
}
